export default {}

console.log(process.env , " process.env")

export const pageTitle = process.env.REACT_APP_PAGE_TITLE;
export const subTitle = 'Powered by Scizers.com';
export const SITEKEY = '6Lf43e4UAAAAAHNjiZf2O_ksmatlFp0chweY6Uxc';
export const dateFormat = 'DD/MM/YY';
export const timeStampFormat = 'DD/MM/YYYY HH:mm A';


export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const appletSocketUrl = process.env.REACT_APPLET_SOCKET_URL;
//export const apiUrl = 'https://api2.amcu.in';
export const apiUrl = process.env.REACT_APP_API_URL;
export const qrCodeUrl = process.env.REACT_APP_QRCODE_URL;

export const cognito = {
    'region': process.env.REACT_APP_COGNITO_REGION,
    'userPoolId': process.env.REACT_APP_USER_POOL_ID,
    'userPoolWebClientId': process.env.REACT_APP_USER_WEBCLIENT_ID
}




