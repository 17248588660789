import axios from "axios";
import { loginWithPasswordUrl, loginWithTokenUrl, sendAuthOtpUrl, verifyAuthOtpUrl } from "../api/loginUrls";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";
import { Auth } from "aws-amplify";

export const loginWithPassword = (inputData, recaptchaToken, sessionId) => async (dispatch) => {
    dispatch({ type: 'LOGIN_SHOW_LOADER' });
    await axios
        .post(loginWithPasswordUrl(), inputData, { Headers: { captcha: recaptchaToken } })
        .then(function ({ data }) {
            if (!data.error) {
                if (data.user.userType !== 'company' && data.user.userType !== 'manager') {
                    dispatch({ type: 'LOGIN_HIDE_LOADER' });

                    return notification.error({
                        message: 'Invalid Login',
                        description: data.message
                    })
                }
                dispatch({
                    type: 'SET_LOGIN_RESPONSE',
                    payload: { mobileNumber: data.user.mobile, userId: data.user.id, sessionId }
                })
                dispatch(sendAuthOtp({ mobileNumber: data.user.mobile, userId: data.user.id, sessionId }))

            } else {
                dispatch({ type: 'LOGIN_HIDE_LOADER' });

                notification.error({
                    message: 'Invalid Login ',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
        });
};
export const loginWithToken = (inputData, recaptchaToken) => async (dispatch) => {
    dispatch({ type: 'LOGIN_SHOW_LOADER' });
    await axios
        .post(loginWithTokenUrl(), inputData, { Headers: { captcha: recaptchaToken } })
        .then(function ({ data }) {
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
            if (data.error || (data.user.userType !== 'company' && data.user.userType !== 'manager')) {
                notification.error({
                    message: 'Invalid Login',
                    description: data.message
                })
            }
            else
                dispatch({
                    type: 'SET_LOGIN_RESPONSE',
                    payload: data,
                });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
        });
};
export const sendAuthOtp = (inputData) => async (dispatch) => {
    dispatch({ type: 'OTP_SEND_SHOW_LOADER' });
    await axios
        .get(sendAuthOtpUrl(inputData), { Headers: { ...getToken().headers } })
        .then(function ({ data }) {
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
            dispatch({ type: 'OTP_SEND_HIDE_LOADER' });

            if (!data.error) {
                if (data.user) {
                    dispatch({
                        type: 'SET_LOGIN_RESPONSE',
                        payload: data,
                    });
                    return
                }
                dispatch({ type: 'SHOW_OTP' })
                dispatch({
                    type: 'SET_SESSION_ID',
                    sessionId: data.sessionId
                })
            }
            else {
                notification.error({
                    message: 'OTP send failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
            dispatch({ type: 'OTP_SEND_HIDE_LOADER' });

        });
};
export const verifyAuthOtp = (inputData) => async (dispatch) => {
    dispatch({ type: 'VERIFICATION_SHOW_LOADER' });
    await axios
        .get(verifyAuthOtpUrl(inputData), { Headers: { ...getToken().headers } })
        .then(function ({ data }) {
            dispatch({ type: 'VERIFICATION_HIDE_LOADER' });

            if (!data.error) {
                dispatch({
                    type: 'SET_LOGIN_RESPONSE',
                    payload: data,
                });
                return;
            }
            else {
                notification.error({
                    message: 'Verification failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'VERIFICATION_HIDE_LOADER' });
        });
};
export const loginWithCognito = (values) => async (dispatch) => {
    dispatch({ type: 'LOGIN_SHOW_LOADER' });

    Auth.signIn(values.email, values.password)
        .then(async (cognitoUser) => {
            dispatch({ type: 'LOGIN_HIDE_LOADER' })
            const { attributes, signInUserSession } = cognitoUser
            const { idToken } = signInUserSession;
            const { jwtToken } = idToken

            const {
                email,
                'custom:userType': userType,
                'custom:mobile': mobile,
                'custom:name': name,
                'custom:companyId': companyId,
                'custom:suspentionTime': suspentionTime,
                'custom:mcpGroups': mcpGroups,
                'custom:id': id

            } = attributes;
            if (userType !== 'company' && userType !== 'manager') {
                return notification.error({
                    message: 'Invalid Login',
                    description: 'try again'
                })
            } else {
                let groups = []
                if (mcpGroups)
                    groups = mcpGroups.split(',')

                let obj = {
                    token: jwtToken,
                    user: {
                        id, email, userType, mobile, name, companyId, suspentionTime, mcpGroups: groups
                    }
                }
                dispatch({
                    type: 'SET_LOGIN_RESPONSE',
                    payload: obj,
                });

            }
        })
        .catch(err => {
            dispatch({ type: 'LOGIN_HIDE_LOADER' })

            notification.error({
                message: err.message,
            })
        });
};