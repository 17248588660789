import axios from "axios";
import { dashboardCollectionsListUrl, dashboardAveragesUrl, dashboardSummaryUrl, offlineMcps, companyStatusUrl } from "../api/dashboardUrl";
import { notification } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";

export const CollectionListAction = (inputData) => async (dispatch) => {

    await axios
        .get(dashboardCollectionsListUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {


            dispatch({ type: 'COLLECTION_LIST', payload: data })
        }
        )
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            console.log(error)
        });
};
export const AveragesAction = (inputData) => async (dispatch) => {
    await axios
        .get(dashboardAveragesUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {

            dispatch({
                type: 'COLLECTION_AVERAGE_RESPONSE',
                payload: data,
            });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            console.log(error)
        });
};
export const SummaryAction = (inputData) => async (dispatch) => {
    await axios
        .get(dashboardSummaryUrl(), { params: { ...inputData }, ...getToken() })
        .then(function ({ data }) {

            dispatch({
                type: 'COLLECTION_SUMMARY_RESPONSE',
                payload: data,
            });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            console.log(error)
        });
};
export const NonActiveMcp = (id, data) => async (dispatch) => {
    await axios
        .get(offlineMcps(id), { ...getToken(), params: { ...data } })
        .then(function ({ data }) {

            dispatch({
                type: 'OFFLINE_MCP',
                payload: data,
            });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            console.log(error)
        });
};
export const CompanyStatusAction = (data) => async (dispatch) => {
    await axios
        .get(companyStatusUrl(), { params: { ...data }, ...getToken() })
        .then(function ({ data }) {
            dispatch({
                type: 'COMPANY_STATUS',
                payload: data,
            });
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            console.log(error)
        });
};
