export default (
    state = {
        tableLoader: false,
        mcpQuantity: { data: [] },
        mcpQuality: { data: [] },
        farmerQuantity: { data: [] },
        farmerQuality: { data: [] }
    },
    action
) => {
    switch (action.type) {
        case "SHOW_LOADER":
            return { ...state, tableLoader: true };
        case "HIDE_LOADER":
            return { ...state, tableLoader: false };
        case "MCP_QUANTITY_LIST":
            return { ...state, mcpQuantity: action.payload }
        case "MCP_QUALITY_LIST":
            return {...state, mcpQuality: action.payload}
        case "FARMER_QUANTITY_LIST":
            return {...state, farmerQuantity: action.payload}
        case "FARMER_QUALITY_LIST":
            return {...state, farmerQuality: action.payload}
        default :
        return state

    }
}