export default (state = {
    List: {}
}, action) => {
    switch (action.type) {
        case 'SYNC_LOGS_SHOW_LOADER':
            return { ...state, loader: true };

        case 'SYNC_LOGS_HIDE_LOADER':
            return { ...state, loader: false };

        case 'SYNC_LOGS':
            return { ...state, List: action.payload };

        default:
            return state;

    }
};
