import { apiUrl } from "../../../settings";

export const loginWithPasswordUrl = () => {
    return apiUrl + '/loginWithPassword';
};

export const loginWithTokenUrl = () => {
    return apiUrl + '/loginWithToken';
};

export const sendAuthOtpUrl = ({ mobileNumber, userId, sessionId }) => {
    return apiUrl + `/authSendOtp?mobileNumber=${mobileNumber}&userId=${userId}&sessionId=${sessionId}`;
};

export const verifyAuthOtpUrl = ({ sessionId, otpInput, userId }) => {
    return apiUrl + `/authVerifyOtp?sessionId=${sessionId}&otpInput=${otpInput}&userId=${userId}`;
};