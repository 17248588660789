import { apiUrl } from "../../../settings";

export const dashboardCollectionsListUrl = () => {
    return apiUrl + '/lastInsertedCollection';
};

export const dashboardAveragesUrl = () => {
    return apiUrl + '/dashboard/averages';
};
export const dashboardSummaryUrl = () => {
    return apiUrl + '/dashboardSummary';
}
export const offlineMcps = (id) => {
    return apiUrl + '/mcp/offline/' + id;
}
export const companyStatusUrl = () => {
    return apiUrl + '/companyStatus';
}
// export const lastInsertedCollectionUrl = () => {
//     return apiUrl + '/lastInsertedCollection';
// }