export default (
    state = {
        announcementLoader: false,
        collectionsList: { data: [] },
        collectionAveragesResp: {},
        summary: {},
        offlineMcps: { data: [] },
        refreshBox: new Date(),
        socketMsg: null,
        companyStatus: null
    },
    action
) => {
    switch (action.type) {
        case "COLLECTION_LIST":
            return { ...state, collectionsList: action.payload };
        case "COLLECTION_AVERAGE_RESPONSE":
            return { ...state, collectionAveragesResp: action.payload };
        case "COLLECTION_SUMMARY_RESPONSE":
            return { ...state, summary: action.payload };
        case "OFFLINE_MCP":
            return { ...state, offlineMcps: action.payload };
        case "REFRESH_BOX":
            return { ...state, refreshBox: action.payload };
        case "ON_MESSAGE":
            return { ...state, socketMsg: action.payload };
        case "COMPANY_STATUS":
            return { ...state, companyStatus: action.payload };
        default:
            return state;
    }
};
